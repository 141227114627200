import React, { useState } from "react";
import ImageSlider from "./ImageSlider";
import ProductCard from "./ProductCard";
import "./ProductShowcase.css";

// Importer les images nécessaires
import productImage1 from "../assets/test2.png";
import productImage2 from "../assets/four-image-profil.png";
import productImage3 from "../assets/four-image-int.png";
import productImage4 from "../assets/four-image-allumage.png";
import productImage5 from "../assets/four-image-poigne.png";
import thumbnailImage1 from "../assets/offre1.png";
import thumbnailImage2 from "../assets/offre2.png";
import thumbnailImage3 from "../assets/full.png";
import klarnaLogo from "../assets/klarna.svg"; // Importer l'image Klarna

const ProductShowcase = () => {
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  const productData = [
    {
      title: "Four à pizza Pro Kit",
      discount: "39% OFF",
      price: "299€",
      originalPrice: "445€",
      description: "CHEF 3***",
      perks: [
        "Pelle & Housse Offert (45€)",
        "Thermomètre digital & Coupe pâton",
        "Farine d'Italie Caputo & Farine pour étaler",
        "Sauce tomate d'Italie",
        "Envoie gratuit",
        "Garantie 2 ans",
      ],
      badge: "PLUS POPULAIRE - SAVE 39%",
      thumbnail: thumbnailImage3,
      paymentLink: "https://buy.stripe.com/eVa14p5tObopaqc7su",
    },
    {
      title: "Four à pizza Medium Kit",
      discount: "43% OFF",
      price: "282.5€",
      originalPrice: "430€",
      description: "CHEF 2**",
      perks: [
        "Pelle & Housse Offert (45€)",
        "Thermomètre digital",
        "Farine d'Italie Caputo",
        "Sauce tomate d'Italie",
        "Envoie gratuit",
        "Garantie 2 ans",
      ],
      badge: "BUNDLE OFFER - SAVE 43%",
      thumbnail: thumbnailImage2,
      paymentLink: "https://buy.stripe.com/9AQ8wRg8s8cd41O7st",
    },
    {
      title: "Four à pizza Starter Kit",
      discount: "43% OFF",
      price: "269.99€",
      originalPrice: "395€",
      description: "CHEF 1*",
      perks: [
        "Pelle & Housse Offert (45€)",
        "Envoie gratuit",
        "Garantie 2 ans",
      ],
      badge: "BEST VALUE - SAVE 43%",
      thumbnail: thumbnailImage1,
      paymentLink: "https://buy.stripe.com/eVacN76xSfEFgOAcMM",
    },
  ];

  const images = [
    productImage1,
    productImage2,
    productImage3,
    productImage4,
    productImage5,
  ];

  const handleSelectProduct = (index) => {
    setSelectedProductIndex(index);
  };

  const handlePayment = () => {
    const url = productData[selectedProductIndex].paymentLink;
  
    // Vérifie si l'URL existe
    if (!url) {
      console.error("Lien de paiement introuvable");
      return;
    }
  
    // Redirige vers Stripe
    window.location.href = url;
  };
  

  return (
    <div id="productShowcase" className="product-showcase-container">
      <div className="header-benefits">
        <div>FREE Pelle & Housse</div>
        <div>ENVOIE GRATUIT</div>
        <div>GARANTIE 2 ANS</div>
      </div>

      <div className="main-content">
        <ImageSlider images={images} discountTag="43% OFF" />

        <div className="product-info">
          <div className="product-description">
            <h2>Four à Pizza</h2>
            <div className="product-rating">
              <span>Excellent</span>
              <span>★★★★★</span>
              <span>70 reviews on Trustpilot</span>
            </div>
            <p>
              Notre four à pizza compact ne prend pas beaucoup de place et ses pieds se replient pour un gain d'espace optimal. Savourez des pizzas authentiques avec une cuisson parfaite à chaque fois.
            </p>
          </div>

          <div className="product-cards">
            {productData.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                isSelected={selectedProductIndex === index}
                onSelect={() => handleSelectProduct(index)}
              />
            ))}
          </div>

          <div className="purchase-options">
            <button className="buy-button" onClick={handlePayment}>
              Paiement - {productData[selectedProductIndex].price}
            </button>
            <div className="klarna-option">
              <img src={klarnaLogo} alt="Klarna" />
              <span>Achetez maintenant, payez plus tard.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductShowcase;
